<template>
  <div class="details" v-loading="pageLoading">
    <div class="header">
      <div class="statusbar">
        <div class="title">
          <span>舱位咨询详情</span> 
          <el-button v-if="pageDetail.status == 0" type="success" size="small">待回复</el-button>
          <el-button v-if="pageDetail.status == 1" type="warning" size="small">有舱位</el-button>
          <el-button v-if="pageDetail.status == 2" type="warning" size="small">有舱位(新运价)</el-button>
          <el-button v-if="pageDetail.status == 3" type="danger" size="small">无舱位</el-button>
          <el-button v-if="pageDetail.status == 4" type="info" plain size="small">已撤销</el-button>
        </div>
        <div>
          <el-button size="small" @click.stop="$router.back()">返回</el-button>
        </div>
      </div>

      <div class="clearfix box">
        <div class="name">
          海运费 <br>
          (含箱型箱量)
        </div>
        <div class="feebox">
          <div v-for="(item, index) in pageDetail.sea_freight" :key="index">
            {{ item.currency }}<span class="red"> {{ item.price }} </span> / <span class="boxtype">{{ item.name
            }}*{{item.quantity || 1}}</span>
          </div>
        </div>
      </div>
    </div>
    <el-form :label-position="'left'" :disabled='true' ref="ruleForm" label-width="120px" class="lrBox">
      <el-card class="box-card" shadow="never">
        <div class="itemBox">
          <el-form-item label="起运港" class="f_item">
            <span>{{ pageDetail.start_port_name_en | textFormat }}</span>
          </el-form-item>
          <el-form-item label="目的港" class="f_item">
            <span>{{ pageDetail.end_port_name_en | textFormat }}</span>
          </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item label="UNNO" class="f_item">
            <span>{{ pageDetail.un_no | textFormat }}</span>
          </el-form-item>
          <el-form-item label="Class" class="f_item">
            <span>{{ pageDetail.classify | textFormat }}</span>
          </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item label="PG" class="f_item">
            <span>{{ pageDetail.pg | textFormat }}</span>
          </el-form-item>
          <el-form-item label="PSN" class="f_item">
            <span>{{ pageDetail.psn | textFormat }}</span>
          </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item label="货重" class="f_item">
            <span>{{ pageDetail.cargo_weight | textFormat }} {{ pageDetail.cargo_weight ? 'KGS' : ' ' }}</span>
          </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item label="订舱客户" class="f_item">
            <span>{{ pageDetail.booking_customer | textFormat }}</span>
          </el-form-item>
          <el-form-item label="客户公司" class="f_item">
            <span>{{ pageDetail.booking_customer_company | textFormat }}</span>
          </el-form-item>
        </div>
        <div class="itemBox">
          <el-form-item label="询价销售" class="f_item">
            <span>{{ pageDetail.sales | textFormat }}</span>
          </el-form-item>
          <el-form-item label="询价商务" class="f_item">
            <span>{{ pageDetail.business | textFormat }}</span>
          </el-form-item>
        </div>

        <div class="itemBox">
          <el-form-item label="备注" class="f_item">
            <span>{{ pageDetail.remarks || '未填写'  }}</span>
          </el-form-item>
        </div>
      </el-card>
    </el-form>

    <div class="card" v-for="item in pageDetail.reply" :key="item.id">
      <div class="head">
        <div class="split"></div>
        <div>舱位更新时间：{{item.updated_time | secondFormat('LLLL')}}</div>
        <div>回复商务：{{item.business}}</div>
        <div>状态：
          <el-button v-if="item.status == 0" type="success" size="small">待回复</el-button>
          <el-button v-if="item.status == 1" type="warning" size="small">有舱位</el-button>
          <el-button v-if="item.status == 2" type="warning" size="small">有舱位(新运价)</el-button>
          <el-button v-if="item.status == 3" type="danger" size="small">无舱位</el-button>
          <el-button v-if="item.status == 4" type="info" plain size="small">已撤销</el-button>
        </div>
      </div>

      <div>
        <el-form :label-position="'left'" :disabled='true' ref="ruleForm" label-width="120px" class="lrBox">
          <el-card class="box-card" shadow="never">
            <div class="itemBox">
              <el-form-item label="海运费">
                <div class="feebox">
                  <div v-for="(li, index) in item.sea_freight" :key="index">
                    {{li.currency}}<span class="red"> {{li.price}} </span>/ <span> {{li.name}}</span> 
                  </div>
                </div>
              </el-form-item>
              
            </div>
            <div class="itemBox">
              <el-form-item label="船司" class="f_item">
                <span v-if="item.shipping_company_code">{{ item.shipping_company_code | textFormat }}</span>
                <span v-else class="tobeadd">待添加</span>
              </el-form-item>
              <el-form-item label="码头" class="f_item">
                <span v-if="item.terminal">{{ item.terminal | textFormat }}</span>
                <span v-else class="tobeadd">待添加</span>
              </el-form-item>
            </div>
            <div class="itemBox">
              <el-form-item label="船期" class="f_item">
                <span v-if="item.shipping_date">{{ item.shipping_date | weekDaysFormat }}</span>
                <span v-else class="tobeadd">待添加</span>
              </el-form-item>
              <el-form-item label="运价备注" class="f_item">
                <span v-if="item.freight_remarks">{{ item.freight_remarks | textFormat }}</span>
                <span v-else class="tobeadd">待添加</span>
              </el-form-item>
            </div>
            <div class="itemBox">
              <el-form-item label="开航日期" class="f_item">
                <span>{{ item.etd | secondFormat('LL') }}</span>
              </el-form-item>
              <el-form-item label="有效期" class="f_item">
                <span v-if="item.validity_start && item.validity_end">
                  {{ item.validity_start | secondFormat('LL') }} 至 {{ item.validity_end | secondFormat('LL') }}
                </span>
                <span v-else class="tobeadd">待添加</span>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
      </div>

      <div class="quick">
        <p class="title" style="padding:20px 10px">舱位提醒：{{item.remind | textFormat}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: { },
  data() {
    return {
      pageLoading: false,
      btnLoading: false,
      availableForm: {
        etd: null,
        remind: '' // 舱位提醒
      },
      pageDetail: {},//详情
      newest: {}, // 最新状态
      form: {},
      dialogFormVisible: false,
      dialogVisible: false,
      showHaveFee: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.queryInfo = JSON.parse(JSON.stringify(this.$route.query));
      this.getDetails()
    })
  },
  methods: {
    handleUpdate(){
      this.$router.push({
        path: '/shippingSpace/updateDetail',
        query: {
          id: this.queryInfo.id
        }
      })
    },
    async getDetails() {
      this.pageLoading = true;
      let params = {
        id: this.queryInfo.id,
        sales_id: this.USER_INFO.id
      }
      let data = await this.$store.dispatch(
        "baseConsole/shippingSpaceDetail",
        params
      )
      if (data.success) {
        this.pageLoading = false;
        if(data.data.reply && data.data.reply.length > 0){
          this.newest = data.data.reply[0];
        }
        this.pageDetail = JSON.parse(JSON.stringify(data.data));
        if(this.pageDetail.sea_freight && this.pageDetail.sea_freight.length){
          for (let index = 0; index < this.pageDetail.sea_freight.length; index++) {
            const element = this.pageDetail.sea_freight[index];
            if(element.price){
              this.showHaveFee = true;
              break;
            }
          }
        }
        if(this.pageDetail.sales_id == this.USER_INFO.id){
          if(this.pageDetail.is_read == 0){
            this.operateHttp(1);
          }
        }
      } else {
        this.pageLoading = false;
        this.$message.error('操作失败')
      }
    },
    async operateHttp(operateType) {
      let params = {
        id: this.pageDetail.id,
      };
      if(operateType && operateType == 1){
        params.is_read = 1; //是否已读：0-否；1-是
      }
      try {
        let res = await this.$store.dispatch(
          "baseConsole/shippingSpaceEdit",
          params
        )
        if (res.success) {}
      } catch (err) {
      }
    },
  }
};

</script>

<style scoped lang="less">
.details{
  background-color: white;
}
.header {
  padding: 15px;
  border-bottom: 5px solid #dddee0;
  font-size: 16px;

  .box {
    display: flex;
    padding-top: 10px;
    align-items: center;
    text-align: center;

    .name {
      width: 120px;
    }

    .feebox {
      display: flex;
      font-size: 24px;

      div {
        width: 260px;
        border-right: 1px solid #ddd;

        &:last-child {
          border: none;
        }
      }

      .boxtype {
        font-size: 20px;
      }

      .red {
        color: red;
        margin-left: 10px;
      }
    }
  }
}

.box-card {
  padding: 20px;
  border: none;
  width: 1000px;

  .itemBox {
    display: flex;

    .f_item {
      width: 50%;
      font-size: 14px;
      line-height: 32px;
    }
    .all_item{
      width: 100%;
      font-size: 14px;
      line-height: 32px;
      ::v-deep .el-form-item__label{
        width: 125px !important;
        margin-left: -20px;
      }
      .red{
        margin: 0 3px;
      }
      div{
        display: flex;
        float: left;
        font-size: 20px;
      }
    }
  }
}

.tobeadd {
  color: #F67723;
}

.quick {
  border: 1px solid #F67723;
  width: 960px;
  border-radius: 4px;
  margin: 0 20px;
  padding: 10px;

  .title {
    color: #F67723;
  }

  .content {
    height: 66px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .el-button {
      width: 200px;
      height: 38px;
    }
  }
}

.card{
  border-top: 1px solid #ddd;
  width: 1000px;
  margin-bottom: 20px;
  padding: 20px 0;
  .head{
    .split{
      width: 2px;
      height: 20px;
      background-color: #409EFF;
      margin-right: 20px;
    }
    div{
      margin-right: 50px;
    }
    display: flex;
    font-size: 16px;
    margin: 0 20px;
    align-items: center;
    padding: 10px;
  }
  .feebox {
    display: flex;
    font-size: 20px;

    div {
      width: 260px;
      border-left: 1px solid #ddd;
      padding-left: 40px;

      &:first-child {
        width: 220px;
        border: none;
        padding-left: 0;
      }
    }

    .boxtype {
      font-size: 20px;
    }

    .red {
      color: red;
      margin-left: 10px;
    }
  }
}
.tips {
  color: #606266;
  font-weight: bolder;
  padding: 32px;
}

.statusbar{
  display: flex;
  justify-content: space-between;
  .title{
    span{
      margin-right: 20px;
      font-size: 14px;
    }
  }
}
</style>
